body {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-family: sans-serif;
    font-size: 13px;
    padding: 1em;
    box-sizing: border-box;
}
