.settingsPanel {
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.settingsPanelButtons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.settingsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 5px;
    transition: all 0.3s;
    position: fixed;
    top: 70px;
    right: 10px
}

.settingsContainerHidden {
    transform: translate(150%);
    visibility: hidden;
}

.settingsPage {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.settingsHeader {
    text-align: center;
}

.settingsList > div {
    margin-bottom: 10px;
}

.settingsList label {
    cursor: pointer;
}

.settingsList input[type='checkbox'] {
    margin-right: 5px;
}
