body, h1, h2, h3, h4 {
    padding: 0;
    margin: 0;
}

.paper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.paper svg {
    width: 100%;
    height: 100%;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}