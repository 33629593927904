.mini-keyboard {
    position: absolute;
    width: calc(35px * 3);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.keyboard {
    border-collapse: collapse;
}

.keyboard td {
    border: 1px solid #b4b8bb;
    box-sizing: border-box;
    height: 30px;
    padding: 0;
}

.keyboard td:not([colSpan]) {
    width: 35px;
}

.keyboard-input-cell {
    background: #fff;
}

.keyboard-input {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center; 
    border: none;
    outline: none;
    background: transparent;
    position: relative;
}

.keyboard-active-input::before {
    position: absolute;
    content: "";
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    pointer-events: none;
    border: 1px solid #00f;
}

.keyboard-empty-input {
    color: gray;
}
.keyboard-active-input span {
    position: relative;
}
.keyboard-active-input span::after {
    content: "";
    background: #000;
    position: absolute;
    height: 20px;
    width: 1px;
    bottom: 0px;
    margin-left: 1px;
    animation: blink-animation 1s steps(2, start) infinite;
    -webkit-animation: blink-animation 1s steps(2, start) infinite;
}
@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.keyboard-button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-size: 1rem;
    background-color: #f5f8fa;
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
    color: #000;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.keyboard-button:hover {
    background-color: #ebf1f5;
}

.keyboard-button:active {
    background-color: #d8e1e8;
}

.keyboard-submit button {
    font-size: 0.8rem;
    background: #e9f0ff;
}

.keyboard-submit button:hover {
    background: #e4ecfd;
}
